<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                    <v-card-title class="title">
                        <v-icon>layers</v-icon>
                        Customers
                        <v-spacer></v-spacer>
                        <add-button permission="section-create" @action="form.dialog = true">New Customer
                        </add-button>
                    </v-card-title>

                    <v-card flat>
                        <v-card-title class="title">
                            <v-flex xs4>
                                <v-text-field
                                        flat
                                        color="primary"
                                        class="pa-0"
                                        label="Search By Name"
                                        v-model="dataSearch"
                                />
                            </v-flex>

                        </v-card-title>
                    </v-card>

                    <v-data-table :headers="headers" :items="form.items.data"
                                  :rows-per-page-items="rowsPerPageItems"
                                  :pagination.sync="pagination"
                                  :total-items="form.items.meta.total"
                                  class="elevation-1">
                        <template slot="items" slot-scope="props">
                            <td>{{ props.index + form.items.meta.from }}</td>
                            <td class="text-xs-left">{{ props.item.name }}</td>
                            <td class="text-xs-left">{{props.item.tel_no}}
                                <p>{{props.item.primary_mobile}}
                                    <span>{{props.item.secondary_mobile}}</span>
                                </p>
                            </td>
                            <td class="text-xs-left">{{props.item.email}}
                                <p v-if="props.item.fax">Fax: {{props.item.fax}} </p>
                            </td>
                            <td class="text-xs-left">{{props.item.address}}</td>
                            <td class="text-xs-left">{{props.item.pan_vat_no}}</td>
                            <td class="text-xs-left">
                                <span v-if="props.item.bank_detail">
                                {{props.item.bank_detail.name ? props.item.bank_detail.name :''}}
                                <p> {{props.item.bank_detail.account_no ? '(' +props.item.bank_detail.account_no + ')'
                                    :''
                                    }}</p>
                                    </span>
                            </td>

                            <td class="justify-right text-xs-right">
                                <edit-button permission="subject-read"
                                             @agree="form.edit(props.item)"/>
                                <delete-button permission="subject-read" @agree="form.delete(props.item.id)"/>
                                <!--<v-icon small class="mr-2" color="primary" @click="editItem(props.item)">edit</v-icon>-->
                                <!--<v-icon small color="error" @click="form.confirm(props.item)"> delete</v-icon>-->
                            </td>
                        </template>
                    </v-data-table>

                </v-card>
            </v-flex>
        </v-layout>
        <v-dialog v-model="form.dialog" persistent max-width="500px">
            <v-card>
                <v-card-title class="title primary white--text">Customer Information</v-card-title>
                <v-card-text>
                    <v-form ref="form" @submit.prevent="save" @keydown.native="form.errors.clear($event.target.name)"
                            @keyup.enter="save" lazy-validation>
                        <v-container grid-list-md>
                            <v-layout row wrap>
                                <v-form ref="form" @submit.prevent="store"
                                        @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="store"
                                        lazy-validation>
                                    <v-layout wrap>
                                        <v-flex xs12 sm6>
                                            <v-text-field v-model="form.name" name="name" label="Name"
                                                          :error-messages="form.errors.get('name')" :height="25"
                                                          autocomplete="off"/>
                                        </v-flex>
                                        <v-flex xs12 sm6>
                                            <v-text-field v-model="form.tel_no" name="tel_no" label="Telephone Number"
                                                          :error-messages="form.errors.get('tel_no')" :height="25"
                                                          autocomplete="off"/>
                                        </v-flex>
                                        <v-flex xs12 sm6>
                                            <v-text-field v-model="form.primary_mobile" name="primary_mobile"
                                                          label="Primary Mobile Number"
                                                          :error-messages="form.errors.get('primary_mobile')"
                                                          :height="25"
                                                          autocomplete="off"/>
                                        </v-flex>
                                        <v-flex xs12 sm6>
                                            <v-text-field v-model="form.secondary_mobile" name="secondary_mobile"
                                                          label="Secondary Mobile Number"
                                                          :error-messages="form.errors.get('secondary_mobile')"
                                                          :height="25"
                                                          autocomplete="off"/>
                                        </v-flex>
                                        <v-flex xs12 sm6>
                                            <v-text-field v-model="form.email" name="email" label="Email"
                                                          :error-messages="form.errors.get('email')" :height="25"
                                                          autocomplete="off"/>
                                        </v-flex>
                                        <v-flex xs12 sm6>
                                            <v-text-field v-model="form.address" name="address" label="Address"
                                                          :error-messages="form.errors.get('address')" :height="25"
                                                          autocomplete="off"/>
                                        </v-flex>
                                        <v-flex xs12 sm6>
                                            <v-text-field v-model="form.fax" name="fax"
                                                          label="Fax"
                                                          :error-messages="form.errors.get('fax')" :height="25"
                                                          autocomplete="off"/>
                                        </v-flex>

                                        <v-flex xs6>
                                            <v-select v-model="form.pan_vat" name="pan_vat" :items="panVat"
                                                      label="Select PAN/VAT" :height="25"/>
                                        </v-flex>
                                        <v-flex xs12 sm6>
                                            <v-text-field v-model="form.pan_vat_no" name="pan_vat_no"
                                                          :label="form.pan_vat === 'V' ? 'VAT Number ': 'PAN Number'"
                                                          :error-messages="form.errors.get('pan_vat_no')" :height="25"
                                                          autocomplete="off"/>
                                        </v-flex>
                                        <v-flex xs12 sm6>
                                            <v-text-field v-model="form.credit_days" name="credit_days"
                                                          label="Credit Days"
                                                          :error-messages="form.errors.get('credit_days')" :height="25"
                                                          autocomplete="off"/>
                                        </v-flex>
                                        <v-flex xs12 sm6>
                                            <v-text-field v-model="form.bank_detail.name" name="bank_name"
                                                          label="Bank Name"
                                                          :error-messages="form.errors.get('bank_name')" :height="25"
                                                          autocomplete="off"/>
                                        </v-flex>
                                        <v-flex xs12 sm6>
                                            <v-text-field v-model="form.bank_detail.account_no" name="account_no"
                                                          label="Bank Account No"
                                                          :error-messages="form.errors.get('account_no')" :height="25"
                                                          autocomplete="off"/>
                                        </v-flex>
                                    </v-layout>
                                </v-form>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="error" flat @click="form.dialog = false" outline>Cancel</v-btn>
                    <v-btn color="warning" flat @click="save(true)" outline>Save And Continue</v-btn>
                    <v-btn color="success" flat @click="save(false)" outline>Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="duplicateDialog" persistent max-width="350">
            <v-card>
                <v-card-title class="headline">Duplicate Data information</v-card-title>
                <v-card-text>Name: Dil Krishna Pila</v-card-text>
                <v-card-text>Primary Mobile: 9851286513</v-card-text>
                <v-card-text>Seconday Mobile: 9851286513</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="duplicateDialog = false">Disagree</v-btn>
                    <v-btn color="green darken-1" text @click="duplicateDialog = false">Agree</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

    export default {
        data: () => ({
            form: new Form({
                    name: '',
                    tel_no: '',
                    primary_mobile: '',
                    secondary_mobile: '',
                    email: '',
                    fax: '',
                    address: '',
                    pan_vat: '',
                    pan_vat_no: '',
                    credit_days: '',
                    bank_detail: {
                        name: 'Mega',
                        account_no: '202020'
                    },
                },
                '/api/customer'
            ),

            isLoading: false,
            rowsPerPageItems: [5, 10, 25, 50, 75, 100],
            pagination: {
                rowsPerPage: 10,
            },
            duplicateData: {},
            duplicateDialog: false,
            dataSearch: '',
            headers: [
                {
                    text: '#',
                    align: 'left',
                    sortable: false,
                    value: 'id'
                },
                {text: 'Name', value: 'name', align: "left"},
                {text: 'Contact Number', value: 'tel_no', align: "left"},
                // {text: 'Mobile', value: 'Mobile', align: "left"},
                {text: 'Email', value: 'email', align: "left"},
                // {text: 'Fax', value: 'fax', align: "left"},
                {text: 'Address', value: 'address', align: "left"},
                {text: 'PAN/VAT', value: 'pan_vat_no', align: "left"},
                {text: 'Bank/Account', value: 'bank', align: "left"},
                {text: 'Actions', value: 'action', sortable: false, align: "right"}
            ],
            panVat: [{value: 'P', text: 'PAN'}, {value: 'V', text: 'VAT'}],
            valid: true,
        }),
        mounted: function () {
            this.get();
        },

        computed: {
            ...mapState(['batch']),
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            },
            'dataSearch': function () {

                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    this.get();
                }, 500);
                // this.get();
            },
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&search=' + this.dataSearch;
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                    this.accountHeads = data.data.map(item => ({value: item.id, text: item.name}));
                })

            },

            save(tag) {
                this.form.closeModal = !tag;
                this.form.store().then(res => {
                    console.log(res)
                }).catch(e => {
                    this.duplicate = e.data.errors
                    console.log(e.data.errors)
                    // console.log(e.respond)
                });
            },
        },

    }
</script>
<style lang="scss">
</style>

